import { Parser, Result, Success, Failure, seqMap, string, regex, sepBy, of } from 'parsimmon';
import { ActionType, Composition } from '../../types/rooms';

// parsers for each type
const zeroOrMoreAdults: Parser<number> = seqMap(string('a'), regex(/[0-9]{1}/), (_, a) => parseInt(a));
const oneOrMoreAdults: Parser<number> = seqMap(string('a'), regex(/[1-9]{1}/), (_, a) => parseInt(a));
const teens: Parser<number> = seqMap(string('t'), regex(/[0-9]{1}/), (_, t) => parseInt(t));
const children: Parser<number> = seqMap(string('c'), regex(/[0-9]{1,2}/), (_, c) => parseInt(c));
const childAges: Parser<number[]> = sepBy(
	seqMap(string('c'), regex(/((1{1}[0-7]{1})|[2-9])/), (_, age) => parseInt(age)),
	string(',')
);
const infants: Parser<number> = seqMap(string('i'), regex(/[0-9]{1}/), (_, i) => parseInt(i));

const roomParser: Parser<RoomComposition> = seqMap(
	oneOrMoreAdults,
	string(',').or(of(undefined)),
	childAges.or(of([])),
	string(',').or(of(undefined)),
	infants.or(of(0)),
	(a, _, ages, __, i) => ({
		adults: a,
		childAges: ages,
		infants: i
	})
);

const flightPartyParser: Parser<FlightPartyComposition> = seqMap(zeroOrMoreAdults, teens, children, infants, (a, t, c, i) => ({
	adults: a,
	teens: t,
	children: c,
	infants: i
}));

const isSuccess = <T>(result: Success<T> | Failure): result is Success<T> => {
	return (result as Success<T>).value !== undefined;
};

const createParser = <T>(parser: Parser<T>): ((compositions: string[]) => T[] | undefined) => {
	return (composition: string[]): T[] | undefined => {
		if (composition == null) {
			return undefined;
		}
		return (composition || []).reduce((acc: T[], next: string) => {
			if (acc == null) {
				return acc;
			}
			if (next == null || next === '') {
				return undefined;
			}
			const result: Result<T> = parser.parse(next);
			return isSuccess(result) ? acc.concat([result.value]) : undefined;
		}, []);
	};
};

export interface RoomComposition {
	adults: number;
	childAges: number[];
	infants: number;
}

export interface FlightPartyComposition {
	adults: number;
	teens: number;
	children: number;
	infants: number;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Rooms {
	export const parse: (compositions: string[]) => RoomComposition[] | undefined = createParser(roomParser);

	export const parseAndConvert: (compositions: string[]) => FlightPartyComposition[] | undefined = (compositions: string[]) =>
		parse(compositions).map((room) => ({
			adults: room.adults + room.childAges.filter((age) => age >= 16).length,
			teens: room.childAges.filter((age) => age >= 12 && age < 16).length,
			children: room.childAges.filter((age) => age < 12).length,
			infants: room.infants
		}));

	export const format: (compositions: RoomComposition[]) => string[] = (compositions: RoomComposition[]) =>
		compositions.map((composition) => {
			const childAges = composition.childAges.length > 0 ? `,c${composition.childAges.join(',c')}` : '';
			const infants = composition.infants > 0 ? `,i${composition.infants}` : '';
			return `a${composition.adults}${childAges}${infants}`;
		});

	export const prettyFormat = (composition: RoomComposition[], roomsDisabled = false): string => {
		if (!composition) return '';

		const total = composition.reduce((acc, room) => acc + room.adults + room.childAges.length + room.infants, 0);
		return `${total} ${total > 1 ? 'people' : 'person'}${
			roomsDisabled ? '' : ` / ${composition.length} ${composition.length > 1 ? 'rooms' : 'room'}`
		}`;
	};

	export const totalPassengers = (composition: RoomComposition[]): number => {
		if (!composition) return 0;

		return composition.reduce((acc, room) => acc + room.adults + room.childAges.length + room.infants, 0);
	};
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Passengers {
	export const parse: (composition: string) => FlightPartyComposition | undefined = (composition: string) =>
		createParser(flightPartyParser)([composition])?.[0];

	export const parseAndConvert: (composition: string) => RoomComposition | undefined = (composition: string) => {
		const parsed = parse(composition);
		if (parsed == null) {
			return undefined;
		}
		return {
			adults: parsed.adults,
			childAges: [...Array(parsed.teens).keys()].map(() => 15).concat([...Array(parsed.children).keys()].map(() => 5)),
			infants: parsed.infants
		};
	};

	export const format: (compositions: FlightPartyComposition[]) => string[] = (compositions: FlightPartyComposition[]) =>
		compositions.map((composition) => {
			return `a${composition.adults}t${composition.teens}c${composition.children}i${composition.infants}`;
		});

	export const prettyFormat = (composition: FlightPartyComposition): string => {
		if (!composition) return '';

		const total = composition.adults + composition.teens + composition.children + composition.infants;
		return `${total} ${total > 1 ? 'people' : 'person'}`;
	};

	export const totalPassengers = (composition: FlightPartyComposition): number => {
		if (!composition) return 0;

		return composition.adults + composition.teens + composition.children + composition.infants;
	};
}

export const updateComposition = (state: Composition[], action: ActionType): Composition[] => {
	switch (action.type) {
		case 'ADD_ROOM':
			return state.concat({ adults: 2, childAges: [] }).slice(0, 9);
		case 'REMOVE_ROOM':
			return state.length > 1 ? state.slice(0, state.length - 1) : state;
		case 'REMOVE_ROOM_BY_INDEX':
			return state.filter((composition, idx) => {
				if (idx !== action.roomIndex) {
					return composition;
				}
			});
		case 'ADD_ADULT':
			return state.map((composition, idx) =>
				idx === action.roomIndex ? { ...composition, adults: Math.min(composition.adults + 1, 9) } : composition
			);
		case 'REMOVE_ADULT':
			return state.map((composition, idx) =>
				idx === action.roomIndex ? { ...composition, adults: Math.max(composition.adults - 1, 1) } : composition
			);
		case 'ADD_CHILD':
			return state.map((composition, idx) =>
				idx === action.roomIndex ? { ...composition, childAges: composition.childAges.concat([null]).slice(0, 9) } : composition
			);
		case 'REMOVE_CHILD':
			return state.map((composition, idx) =>
				idx === action.roomIndex
					? {
							...composition,
							childAges: composition.childAges.slice(0, composition.childAges.length - 1)
						}
					: composition
			);
		case 'CHANGE_CHILD_AGE':
			return state.map((composition, idx) =>
				idx === action.roomIndex
					? {
							...composition,
							childAges: composition.childAges.map((age, idx) => (idx === action.ageIndex ? action.age : age))
						}
					: composition
			);
		default:
			return state;
	}
};
