import { BRANDS, CJS_BRAND } from '../lib/utils';
import { APP_CONSTANTS } from '../lib/utils/constants';
import { Config } from '../types/config';
import tokens from '@vaa-component-lib/shared.design-tokens/json/variables.json';
import { PRODUCT_TYPES } from '../types/criteria';

export const config: Config = {
	brand: BRANDS.VAH,
	key: CJS_BRAND.VAH,
	niceName: 'Virgin Atlantic Holidays',
	heading: 'So, where next?',
	summary: {
		title: 'Where next?',
		button: 'Start a search',
		editButton: 'Edit search',
		ticker: {
			title: 'Search for',
			hasImages: true,
			actions: ['flights to', 'holidays in'],
			locations: [
				{
					name: 'New York',
					image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/new-york-1.jpg`
				},
				{
					name: 'New York',
					image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/new-york-2.jpg`
				},
				{ name: 'Orlando', image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/orlando-1.jpg` },
				{ name: 'Orlando', image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/orlando-2.jpg` }
			]
		}

		//
	},
	theme: {
		palette: {
			background: tokens.color.light.background_inverted_primary
		}
	},
	bookingTypes: [
		{
			id: '1',
			name: 'Complete holiday',
			type: PRODUCT_TYPES.HOLIDAY,
			icon: 'icon-travel-plane',
			brand: CJS_BRAND.VAH
		},
		{
			id: '2',
			name: 'Multi-destination',
			type: PRODUCT_TYPES.MULTICENTRE,
			icon: 'icon-travel-map-marker',
			brand: CJS_BRAND.VAH
		}
	]
};
