import styled from '@emotion/styled';
import { css } from '@emotion/react';
import tokens from '@vaa-component-lib/shared.design-tokens/json/variables.json';
import { rgba } from 'polished';

import { BACKGROUND_HEIGHTS, NOTCH_HEIGHT, Z_INDICES } from '../../lib/utils/constants';
import { BreakPointToken } from '../../hooks/useMediaQuery';
import { NoGapSupport } from '../../lib/utils/mixins';

import triggerCustomEvent from '../../lib/utils/trigger-custom-event';

type SearchExperienceProps = {
	visible: boolean;
	animate: boolean;
};

type BackgroundPanelProps = {
	show: boolean;
	animate: boolean;
};

type InnerPanelProps = {
	show: boolean;
	animate: boolean;
	initiallyOpen: boolean;
};

type CloseButtonProps = {
	show: boolean;
};

type BookingTypesListProps = {
	show: boolean;
	animate: boolean;
};

type BookingTypesListItemProps = {
	show: boolean;
};

type SearchContainerProps = {
	show: boolean;
	animate: boolean;
};

const {
	spacing: {
		size_2px: { height: size_2 },
		size_4px: { height: size_4 },
		size_8px: { height: size_8 },
		size_12px: { height: size_12 },
		size_16px: { height: size_16 },
		size_20px: { height: size_20 },
		size_24px: { height: size_24 }
	},
	color: colours
} = tokens;

const {
	default: { opened: defaultOpenedHeight },
	desktop: { opened: desktopOpenedHeight, closed: desktopClosedHeight }
} = BACKGROUND_HEIGHTS;

const getAnimationTime = (animate: boolean): string => (animate ? '0.4s' : '0s');
const getAnimationDelay = (animate: boolean): string => (animate ? '0.6s' : '0s');
const getAnimationDelayExtended = (animate: boolean): string => (animate ? '1s' : '0s');

export const SearchExperience = styled.div<SearchExperienceProps>(({ animate, visible }) => {
	return `
	padding: ${size_20}px 0 0;
	position: fixed;
	top: 0;
	right: 0;
	z-index: ${Z_INDICES.base + 1};
	color: ${colours.light.neutral_0};
	transition:
	transform ${getAnimationTime(animate)} ease-in-out,
	width ${getAnimationTime(animate)} ease-in-out;
	transform: ${visible ? 'translateX(0)' : 'translateX(100%)'};
	width: ${visible ? '100%' : '0'};
	height: 100vh;
	height: calc(var(--calculated-vh) * 100);
	background: ${colours.light.neutral_0};

	section[class^='container-module'] {
		position: relative;

		@media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: ${BreakPointToken.MdMax}px)  {
			overflow: scroll;
		}
	}

	@media (min-width: ${BreakPointToken.MdMin}px) {
		transform: none;
		display: ${visible ? 'block' : 'none'};
		height: auto;
		background: none;
		position: relative;
		top: auto;
		right: auto;
	}
`;
});

export const BackgroundPanel = styled('div')<BackgroundPanelProps>(({ theme, show, animate }) => {
	const { palette } = theme;
	const desktopPanelHeight = show ? desktopOpenedHeight : desktopClosedHeight;

	return `
		background: ${
			palette.gradient
				? `linear-gradient(${palette.gradient.angle}deg, ${palette.gradient.start} 0%, ${palette.gradient.end} 100%);`
				: palette.background
		};
		height: ${defaultOpenedHeight};
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		@media (min-width: ${BreakPointToken.MdMin}px) {
			transition: opacity ${getAnimationTime(animate)} ease-in-out, height ${getAnimationTime(animate)} ease-in-out;
			height: ${desktopPanelHeight};
			min-height: 200px;
			opacity: ${show ? 1 : 0};

			&:after {
				content: '';
				width: 100%;
				height: ${size_4}px;
				background: ${rgba(colours.light.neutral_0, 0.16)};
				position: absolute;
				bottom: ${-1 * size_4}px;
				opacity: ${show ? 1 : 0};
				transition: opacity ${getAnimationTime(animate)} ease-in-out;
			}
		}
	`;
});

export const InnerPanel = styled.div<InnerPanelProps>(({ show, animate, initiallyOpen }) => {
	return `
		position: relative;

		@media (min-width: ${BreakPointToken.MdMin}px) {
			overflow: ${initiallyOpen ? 'visible' : 'hidden'}
			pointer-events: ${show ? 'auto' : 'none'};
			max-height: ${show ? '1000px' : '10px'};
			margin-bottom: ${show ? `${-1 * NOTCH_HEIGHT}px` : '0'};
			margin-top: ${show ? '0' : '-5%'};
			opacity: ${show ? '1' : '0'};

			transition:
				margin ${getAnimationTime(animate)} ease-in-out,
				opacity ${getAnimationTime(animate)} ease-in-out,
				max-height ${getAnimationTime(animate)} ease-in-out,
				transform ${getAnimationTime(animate)} ease-in-out;
		}
	`;
});

export const BookingTypeMeta = styled.section`
	color: black;
`;

export const CloseButton = styled.div<CloseButtonProps>(({ show }) => {
	return `
	position: absolute;
	top: 0;
	right: 0;
	opacity: ${show ? `1` : '0'};
	transition:
	opacity 0.4s ease-in-out;
`;
});

export const BookingTypesList = styled.ul<BookingTypesListProps>(({ show, animate }) => {
	return `
	list-style: none;
	margin: ${size_24 - size_2}px ${-1 * size_16}px ${size_20 - size_2}px;
	padding: ${size_2}px ${size_16}px;
	display: flex;
	overflow: auto;
	@media (min-width: ${BreakPointToken.MdMin}px) {
		overflow: visible;
	}
	transition:
		gap ${getAnimationTime(animate)} ease-in-out ${getAnimationDelay(animate)},
		opacity ${getAnimationTime(animate)} ease-in-out ${getAnimationDelay(animate)},
		transform ${getAnimationTime(animate)} ease-in-out ${getAnimationDelay(animate)};

	gap: ${show ? `${size_8}px` : 0};
	opacity: ${show ? 1 : 0};
	transform: ${show ? 'translateX(0)' : 'translateX(-5%)'};

	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	@media (min-width: ${BreakPointToken.MdMin}px) {
		margin: ${size_16}px ${-1 * size_2}px ${-1 * size_2}px ${-1 * size_2}px;
		padding: ${size_2}px;
		gap: ${size_8}px;
		opacity: 1;
		transform: none;
	}
	`;
});

export const BookingTypesListItem = styled.li<BookingTypesListItemProps>(
	({ show }) => css`
		display: flex;
		align-items: center;
		flex-shrink: 0;
		position: relative;

		${NoGapSupport(
			() => css`
				transition: margin ${getAnimationTime(show)} ease-in-out ${getAnimationDelay(show)};

				& + & {
					margin: ${show ? `0 0 0 ${size_8}px` : 0};

					@media (min-width: ${BreakPointToken.MdMin}px) {
						margin: 0 0 0 ${size_8}px;
					}
				}
			`
		)}
	`
);

export const SaleFlashOuter = styled.p`
	display: none;
	@media (min-width: ${BreakPointToken.MdMin}px) {
		display: block;
	}
	position: absolute;
	top: -24px;
	left: 50%;
	transform: translateX(-50%);

	&:empty {
		span {
			display: none;
		}
	}
`;

export const SaleFlashInner = styled.span`
	background: var(--token-color-colour-neutral-10);
	color: var(--token-color-colour-text-primary);
	display: block;
	border-radius: 4px;
	padding: 4px 8px;
	max-width: 20ch;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&::after {
		content: '';
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 4px 4.5px 0 4.5px;
		border-color: var(--token-color-colour-neutral-10) transparent transparent transparent;
		transform: rotate(0deg);
		position: absolute;
		bottom: -3px;
		left: calc(50% - 4.5px);
		transform-origin: center;
		font-family: 'Gotham, Gotham-Book';
		font-size: 12px;
		letter-spacing: normal;
		font-weight: 325;
		line-height: 16px;
	}

	&:empty {
		display: none;
	}
`;

export const SearchContainer = styled.section<SearchContainerProps>(({ show, animate }) => {
	return `
	background: var(--token-color-colour-background-primary);
	color: var(--token-color-colour-text-primary);
	border-radius: ${size_12}px ${size_12}px 0 0;
	margin: 0 ${-1 * size_16}px;
	padding: ${size_24}px ${size_16}px;
	height: calc((var(--calculated-vh) * 100) - (${defaultOpenedHeight} - ${size_12}px));
	transition:
		opacity ${getAnimationTime(animate)} ease-in-out ${getAnimationDelayExtended(animate)},
		transform ${getAnimationTime(animate)} ease-in-out ${getAnimationDelayExtended(animate)};

	opacity: ${show ? 1 : 0};
	transform: ${show ? 'none' : 'translateY(-2%)'};
	overflow-y: auto;
	overflow-x: hidden;

	@media (min-width: ${BreakPointToken.MdMin}px) {
		overflow: initial;
		border-radius: ${size_12}px;
		height: auto;
		margin: ${size_20}px 0 0;
		padding: ${size_16}px;
		opacity: 1;
		transform: none;
		box-shadow: 0 ${size_4}px ${size_16}px 0 ${rgba(colours.light.neutral_100, 0.08)};
		transition:
			opacity ${getAnimationTime(animate)} ease-in-out ${getAnimationDelay(animate)},
			transform ${getAnimationTime(animate)} ease-in-out ${getAnimationDelay(animate)};
	}


	// TODO: was originally LgMin - does it need to change?
	@media (min-width: ${BreakPointToken.LgMin}px) {
		padding: ${size_24}px;
	}
`;
});
