import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { TypographyComponent, TypographyVariant, TypographySize, TypographyWeight } from '@vaa-component-lib/component.atom.typography';
import { BookingType, Config } from '../../types/config';
import {
	BackgroundPanel,
	BookingTypeMeta,
	BookingTypesList,
	BookingTypesListItem,
	CloseButton,
	InnerPanel,
	SearchContainer,
	SearchExperience,
	SaleFlashInner,
	SaleFlashOuter
} from './search-experience.styles';
import {
	IconActionCrossComponent,
	IconTravelHotelComponent,
	IconTravelPlaneComponent,
	IconTravelUpgradeComponent,
	IconTravelMapMarkerComponent,
	IconTravelFlightPlusHotelComponent,
	IconTravelCarHireComponent
} from '@vaa-component-lib/component.atom.icons';
import { ButtonColour, ButtonComponent, ButtonPriority, ButtonSize, ButtonType } from '@vaa-component-lib/component.atom.button';
import { useApplication } from '../../hooks/useApplication';
import { useBlanket } from '../../hooks/useBlanket';
import useMediaQuery, { BreakPointToken } from '../../hooks/useMediaQuery';
import { ContainerComponent } from '@vaa-component-lib/component.layout.container';
import { PRODUCT_TYPES } from '../../types/criteria';

const FlightOnlyComponent = lazy(() => import('../forms/flight.component'));
const HolidayComponent = lazy(() => import('../forms/holiday.component'));
const FlightCarComponent = lazy(() => import('../forms/flight-car.component'));

interface SearchExperienceComponentProps {
	config: Config;
	shouldAnimate?: boolean;
	initialOpenState?: boolean;
}

const SearchExperienceComponent = ({ config, shouldAnimate = false, initialOpenState = true }: SearchExperienceComponentProps) => {
	const { application, setApplication } = useApplication();
	const { blanket, setBlanket } = useBlanket();
	const [locale, setLocale] = useState<string>('en-GB');
	let defaultBookingType: BookingType = config?.bookingTypes[0];

	if (application?.criteria?.searchType !== undefined) {
		defaultBookingType = config?.bookingTypes?.find((item) => item?.type === application?.criteria?.searchType);
	}

	const [selectedBookingType, setSelectedBookingType] = useState<BookingType>(defaultBookingType);
	const [isBlock, setIsBlock] = useState<boolean>(false);
	const [isShown, setIsShown] = useState<boolean>(false);
	const topRef = useRef<HTMLDivElement>(null);

	const isTablet = useMediaQuery(`(min-width: ${BreakPointToken.MdMin}px)`);

	const mapIcon = (icon: string) => {
		switch (icon) {
			case 'icon-travel-plane':
				return <IconTravelPlaneComponent />;
				break;
			case 'icon-travel-hotel':
				return <IconTravelHotelComponent />;
				break;
			case 'icon-travel-upgrade':
				return <IconTravelUpgradeComponent />;
				break;
			case 'icon-travel-map-marker':
				return <IconTravelMapMarkerComponent />;
				break;
			case 'icon-travel-flight-plus-hotel':
				return <IconTravelFlightPlusHotelComponent />;
				break;
			case 'icon-travel-car-hire':
				return <IconTravelCarHireComponent />;
				break;
		}
	};

	const closeExperience = () => {
		setBlanket({ ...blanket, show: false });
		setApplication({ ...application, open: false });
	};

	useEffect(() => {
		// This is used to ensure that the document doesn't jump horizontally each time the scrollbars are
		// shown or hidden (for example: when Blanket or the Menu is triggered)
		if (document) {
			const documentEl = document.documentElement;
			Object.assign(documentEl.style, { 'overflow-x': 'hidden', 'margin-right': 'calc(-1 * (100vw - 100%))' });
		}
	}, []);

	useEffect(() => {
		setLocale(navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language || 'en-GB');
	}, []);

	useEffect(() => {
		let animationTimer: NodeJS.Timeout;
		let overflowTimer: NodeJS.Timeout;

		const { open } = application;

		const animateInTime = shouldAnimate ? 25 : 0;
		const animateOutTime = shouldAnimate ? 400 : 0;

		if (open) {
			setIsBlock(true);
			animationTimer = setTimeout(() => {
				setIsShown(true);

				if (!initialOpenState) {
					topRef.current.focus();
				}
			}, animateInTime);
		} else {
			setIsShown(false);
			animationTimer = setTimeout(() => setIsBlock(false), animateOutTime);
		}

		overflowTimer = setTimeout(() => {
			if (document) {
				const currentlyTablet = window.matchMedia(`(min-width: ${BreakPointToken.MdMin}px)`).matches;

				const documentEl = document.documentElement;
				const hideOverflow = (open && !currentlyTablet) || (open && !currentlyTablet && !initialOpenState);

				documentEl.style.overflow = hideOverflow ? 'hidden' : '';
				document.body.style.overflow = hideOverflow ? 'hidden' : '';
			}
		}, animateOutTime * 2);

		return () => {
			clearTimeout(animationTimer);
		};
	}, [application]);

	return (
		<SearchExperience data-cy="search-experience-component" visible={isTablet ? isBlock : isShown} animate={shouldAnimate} role="region">
			<BackgroundPanel show={isShown} animate={shouldAnimate} />
			<ContainerComponent as="section">
				<InnerPanel show={isShown} animate={shouldAnimate} initiallyOpen={initialOpenState}>
					<div ref={topRef}>
						{application.heading && (
							<TypographyComponent
								variant={TypographyVariant.Heading}
								size={TypographySize.Mdm}
								weight={TypographyWeight.Regular}
								element="h1"
								data-cy={'application-heading'}
								aria-label={`${application.heading}, begin your search...`}>
								{application.heading}
							</TypographyComponent>
						)}
					</div>

					<BookingTypesList show={isShown} animate={shouldAnimate} data-cy="booking_types_tabs">
						{config.bookingTypes
							.filter((bookingType: BookingType) => {
								if (
									locale !== 'en-GB' &&
									(bookingType?.type === PRODUCT_TYPES.FLIGHT_HOTEL ||
										bookingType?.type === PRODUCT_TYPES.HOLIDAY ||
										bookingType?.type === PRODUCT_TYPES.FLIGHT_CAR)
								) {
									return null;
								} else {
									return bookingType;
								}
							})
							.map((bookingType: BookingType, idx: number) => {
								return (
									<BookingTypesListItem show={isShown} key={idx} data-cy={`${bookingType?.type}`}>
										<SaleFlashOuter id={`sales_flash_${bookingType?.type}`}>
											<SaleFlashInner data-target />
										</SaleFlashOuter>

										<ButtonComponent
											text={bookingType.name}
											size={ButtonSize.Small}
											iconChild={mapIcon(bookingType.icon)}
											priority={ButtonPriority.Selector}
											colour={ButtonColour.ContrastSecondary}
											isActive={bookingType?.id === selectedBookingType?.id}
											onClick={() => setSelectedBookingType(bookingType)}
											// @ts-ignore
											tabIndex={isShown ? 0 : -1}
										/>
									</BookingTypesListItem>
								);
							})}
					</BookingTypesList>
					<SearchContainer show={isShown} animate={shouldAnimate}>
						<Suspense fallback="Loading...">
							<BookingTypeMeta>
								{selectedBookingType.type === PRODUCT_TYPES.FLIGHT_ONLY && <FlightOnlyComponent bookingType={selectedBookingType} />}
								{selectedBookingType.type === PRODUCT_TYPES.REWARD_FLIGHT && <FlightOnlyComponent bookingType={selectedBookingType} />}
								{selectedBookingType.type === PRODUCT_TYPES.FLIGHT_HOTEL && <HolidayComponent bookingType={selectedBookingType} />}
								{selectedBookingType.type === PRODUCT_TYPES.HOLIDAY && <HolidayComponent bookingType={selectedBookingType} />}
								{selectedBookingType.type === PRODUCT_TYPES.HOTEL && <HolidayComponent bookingType={selectedBookingType} />}
								{selectedBookingType.type === PRODUCT_TYPES.FLIGHT_CAR && <FlightCarComponent bookingType={selectedBookingType} />}
							</BookingTypeMeta>
						</Suspense>
					</SearchContainer>
					{(application?.closable || !isTablet) && (
						<CloseButton show={isShown}>
							<ButtonComponent
								onClick={() => closeExperience()}
								priority={ButtonPriority.Tertiary}
								colour={ButtonColour.Inverse}
								size={ButtonSize.Small}
								type={ButtonType.Icon}
								aria-label="Close search panel"
								iconChild={<IconActionCrossComponent />}
								data-cy={'close-button'}
								// @ts-ignore
								tabIndex={isShown ? 0 : -1}
							/>
						</CloseButton>
					)}
				</InnerPanel>
			</ContainerComponent>
		</SearchExperience>
	);
};

export default SearchExperienceComponent;
