import { DateTime } from 'luxon';
import { BRANDS, parseBool } from '.';
import { Criteria, FLIGHT_ONLY } from '../../types/criteria';
import { State } from '../../types/state';
import { STATE_DEFAULTS } from './constants';

const sanitizeUrl = require('@braintree/sanitize-url').sanitizeUrl;

export default class UrlService {
	url: URL;
	params: URLSearchParams;

	constructor(i: string) {
		this.url = new URL(i);
		this.params = this.url.searchParams;
	}

	getBrand(): BRANDS {
		let brand = STATE_DEFAULTS.BRAND;
		let brandParam = this.params.get('brand');
		if (brandParam) {
			const values = Object.values(BRANDS);
			const p = brandParam.toUpperCase();
			if (values.includes(p as unknown as BRANDS)) {
				return p as unknown as BRANDS;
			}
		}
		return brand;
	}

	getState(): State {
		const brand = this.getBrand();
		// Set the default state
		let state: State = {
			open: STATE_DEFAULTS.OPEN,
			closable: STATE_DEFAULTS.CLOSABLE,
			float: STATE_DEFAULTS.FLOAT,
			brand: brand
		};

		let openParam = this.params.get('state:open');
		if (openParam && parseBool(openParam) != null) {
			state.open = parseBool(openParam) as boolean;
		}

		let floatParam = this.params.get('state:float');
		if (floatParam && parseBool(floatParam) != null) {
			state.float = parseBool(floatParam) as boolean;
		}

		let closableParam = this.params.get('state:closable');
		if (closableParam && parseBool(closableParam) != null) {
			state.closable = parseBool(closableParam) as boolean;
		}

		let headingParam = this.params.get('state:heading');
		if (headingParam && headingParam != null) {
			state.heading = sanitizeUrl(headingParam);
		}

		return state;
	}
}
