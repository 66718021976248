import styled from '@emotion/styled';
import { css } from '@emotion/react';
import tokens from '@vaa-component-lib/shared.design-tokens/json/variables.json';

import { BreakPointToken } from '../../hooks/useMediaQuery';
import { NoGapSupport } from '../../lib/utils/mixins';

interface TickerPanelProps {
	hasImages: boolean;
}

interface DestinationPanelProps {
	timing: number;
	number: number;
	animate: boolean;
}

interface DestinationImageProps {
	timing: number;
	number: number;
}

interface TickerTextProps {
	timing: number;
	state: 'new' | 'old' | null;
	secondary: boolean;
}

const bounceTransition = 'cubic-bezier(.34,1.56,.64,1)';
const easeTransition = 'ease-in-out';

const {
	spacing: {
		size_2px: { height: size_2 },
		size_8px: { height: size_8 },
		size_12px: { height: size_12 },
		size_40px: { height: size_40 },
		size_48px: { height: size_48 }
	},
	color: colours
} = tokens;

const getImageState = (number: number, animate: boolean): { opacity: number; transform: string } => {
	const transform = animate ? `translateX(${-1 * size_12}px)` : 'translateX(0)';

	if (animate) {
		switch (number) {
			case 1:
				return {
					opacity: 0,
					transform: transform
				};
			case 2:
				return {
					opacity: 1,
					transform: transform
				};
			case 3:
				return {
					opacity: 0.68,
					transform: transform
				};
			case 4:
				return {
					opacity: 0.36,
					transform: transform
				};
			default:
				return {
					opacity: 0,
					transform: 'none'
				};
		}
	} else {
		switch (number) {
			case 1:
				return {
					opacity: 1,
					transform: transform
				};
			case 2:
				return {
					opacity: 0.68,
					transform: transform
				};
			case 3:
				return {
					opacity: 0.36,
					transform: transform
				};
			case 4:
				return {
					opacity: 0,
					transform: transform
				};
			default:
				return {
					opacity: 0,
					transform: 'none'
				};
		}
	}
};

const getTickerState = (state: 'new' | 'old' | null, secondary: boolean): { opacity: number; transform: string } => {
	switch (state) {
		case 'new':
			return {
				opacity: 1,
				transform: 'translateY(0)'
			};
		case 'old':
			return {
				opacity: 0,
				transform: 'translateY(-100%)'
			};
		default:
			return {
				opacity: secondary ? 0 : 1,
				transform: secondary ? 'translateY(100%)' : 'translateY(0)'
			};
	}
};

export const TickerPanel = styled.div<TickerPanelProps>`
	padding: 0 ${size_12}px;
	text-align: left;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;

	@media (min-width: ${BreakPointToken.MdMin}px) {
		height: ${size_48}px;
		padding: ${({ hasImages }) => (hasImages ? '0' : `0 ${size_12}px`)};
	}
`;

export const DestinationTeaser = styled.div`
	display: none;
	margin: ${-1 * size_2}px 0;

	@media (min-width: ${BreakPointToken.MdMin}px) {
		display: flex;
	}
`;

export const DestinationPanel = styled.div<DestinationPanelProps>`
	width: ${4 + size_48}px;
	height: ${4 + size_48}px;
	border: ${size_2}px solid ${colours.light.background_primary};
	border-radius: ${size_48}px;
	overflow: hidden;
	background: ${colours.light.background_tertiary};
	margin-left: ${-1 * size_40}px;
	z-index: ${({ number }) => 4 - number};
	transition:
		transform ${({ timing }) => timing / 10}ms ${easeTransition},
		opacity ${({ timing }) => timing / 8}ms ${easeTransition};

	${({ number, animate }) => {
		const styles = getImageState(number, animate);
		return `
			opacity: ${styles.opacity};
			transform: ${styles.transform};
		`;
	}}
	&:first-of-type {
		margin-left: 0;
	}
`;

export const DestinationImage = styled.img<DestinationImageProps>`
	width: ${4 + size_48}px;
	height: ${size_48}px;
	opacity: 0;
	transition: opacity ${({ timing }) => timing / 8}ms ${easeTransition};
	transition-delay: ${({ number, timing }) => number * (timing / 18)}ms;
`;

export const TickerContainer = styled.div`
	flex-grow: 1;

	@media (min-width: ${BreakPointToken.LgMin}px) {
		display: flex;
		gap: ${size_8}px;

		${NoGapSupport(
			() => css`
				& > *:first-of-type {
						margin: 0 ${size_8}px 0 0;
					}
				}
			`
		)}
	}
`;

export const TickerWrap = styled.span`
	overflow: hidden;
	position: relative;
	display: block;
	flex-grow: 1;

	${NoGapSupport(
		() => css`
			margin-left: 0.3em;
		`
	)}
`;

export const TickerText = styled.span<TickerTextProps>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;

	${({ state, secondary }) => {
		const styles = getTickerState(state, secondary);
		return `
			opacity: ${styles.opacity};
			transform: ${styles.transform};
		`;
	}}

	transition: transform ${({ timing }) => timing / 6}ms ${bounceTransition},
		opacity ${({ timing }) => timing / 4}ms ${bounceTransition};
`;
