import React, { useEffect, useState } from 'react';
import { SummaryPlayback } from './summary-playback.component.styles';
import { useApplication } from '../../hooks/useApplication';
import { FLIGHT_HOTEL, FLIGHT_ONLY, HOLIDAY, PRODUCT_TYPES, REWARD_FLIGHT, HOTEL } from '../../types/criteria';
import { DATE_FORMATS } from '../../lib/utils/constants';
import { Passengers, Rooms } from '../../lib/utils/composition.service';
import { TypographyComponent, TypographyVariant, TypographySize, TypographyWeight } from '@vaa-component-lib/component.atom.typography';
import useMediaQuery, { BreakPointToken } from '../../hooks/useMediaQuery';

const SummaryPlaybackComponent: React.FC = () => {
	const { application, setApplication } = useApplication();
	const [title, setTitle] = useState<string>('');
	const [contents, setContents] = useState<string[]>([]);

	const isNotMobile = useMediaQuery(`(min-width: ${BreakPointToken.MdMin}px)`);

	const setFlightDates = (): string => {
		switch ((application?.criteria as FLIGHT_ONLY | REWARD_FLIGHT)?.tripType) {
			case 'ONE_WAY':
				return `${(application?.criteria as FLIGHT_ONLY | REWARD_FLIGHT).departing[0]?.toFormat(DATE_FORMATS.DISPLAY_DATE)}`;
				break;
			case 'ROUND_TRIP':
				return `${(application?.criteria as FLIGHT_ONLY | REWARD_FLIGHT).departing[0]?.toFormat(DATE_FORMATS.FIELD_DISPLAY_DATE)} - ${(
					application?.criteria as FLIGHT_ONLY | REWARD_FLIGHT
				).departing[1]?.toFormat(DATE_FORMATS.DISPLAY_DATE)}`;
				break;
		}
	};

	useEffect(() => {
		switch (application?.criteria?.searchType) {
			case PRODUCT_TYPES.FLIGHT_ONLY:
			case PRODUCT_TYPES.REWARD_FLIGHT:
				const flight_criteria = application?.criteria as FLIGHT_ONLY | REWARD_FLIGHT;
				setTitle(
					`${application?.criteria?.searchType === PRODUCT_TYPES.REWARD_FLIGHT ? `Reward Flights` : `Flights`} to ${
						flight_criteria.destination[0]?.cityName
					}`
				);
				setContents([`From ${flight_criteria.origin[0]?.airportCode}`, setFlightDates(), `${Passengers.prettyFormat(flight_criteria?.passengers)}`]);
				break;
			case PRODUCT_TYPES.FLIGHT_HOTEL:
			case PRODUCT_TYPES.HOLIDAY:
				const flight_hotel_criteria = application?.criteria as FLIGHT_HOTEL | HOLIDAY;
				setTitle(
					`${application?.criteria.searchType === PRODUCT_TYPES.FLIGHT_HOTEL ? 'Flight + Hotel' : 'Holiday'} in ${
						flight_hotel_criteria.location?.locationDescription
					}`
				);
				setContents([
					`From ${flight_hotel_criteria.gateway?.code}`,
					`${flight_hotel_criteria.departureDate?.toFormat(DATE_FORMATS.FIELD_DISPLAY_DATE)} -
					${flight_hotel_criteria.departureDate?.plus({ days: flight_hotel_criteria?.duration }).toFormat(DATE_FORMATS.DISPLAY_DATE)}`,
					`${Rooms.totalPassengers(flight_hotel_criteria.rooms)} ${
						Rooms.totalPassengers(flight_hotel_criteria.rooms) > 1 ? 'people' : 'person'
					} / ${flight_hotel_criteria.rooms?.length} ${flight_hotel_criteria.rooms?.length === 1 ? 'room' : 'rooms'}`
				]);
				break;
			case PRODUCT_TYPES.HOTEL:
				const hotel_criteria = application?.criteria as HOTEL;
				setTitle(`Hotel in ${hotel_criteria.location?.locationDescription}`);
				setContents([
					`${hotel_criteria.departureDate?.toFormat(DATE_FORMATS.FIELD_DISPLAY_DATE)} -
					${hotel_criteria.departureDate?.plus({ days: hotel_criteria?.duration }).toFormat(DATE_FORMATS.DISPLAY_DATE)}`,
					`${Rooms.totalPassengers(hotel_criteria.rooms)} ${Rooms.totalPassengers(hotel_criteria.rooms) > 1 ? 'people' : 'person'} / ${
						hotel_criteria.rooms?.length
					} ${hotel_criteria.rooms?.length === 1 ? 'room' : 'rooms'}`
				]);
				break;
		}
	}, []);

	if (application?.criteria === null) {
		return <></>;
	}

	return (
		<SummaryPlayback data-cy="playback-component">
			<TypographyComponent
				element={'h1'}
				data-cy="playback-title"
				variant={TypographyVariant.Body}
				size={isNotMobile ? TypographySize.Mdm : TypographySize.Sml}
				weight={TypographyWeight.Medium}>
				{title}
			</TypographyComponent>
			<TypographyComponent
				element={'p'}
				data-cy="playback-content"
				variant={TypographyVariant.Body}
				size={isNotMobile ? TypographySize.Mdm : TypographySize.Xsm}
				weight={TypographyWeight.Regular}>
				{contents.map((content, i) => (
					<span key={i}>{content}</span>
				))}
			</TypographyComponent>
		</SummaryPlayback>
	);
};

export default SummaryPlaybackComponent;
