export const isServer = typeof window === 'undefined';

export enum BRANDS {
	VAA = 'VAA',
	VAH = 'VAH'
}

export enum CJS_BRAND {
	VAA = 'TRAVELPLUS',
	VAH = 'VHOLS'
}

export interface AppProps {
	brand: BRANDS;
}

export const parseJSON = (jsonString: string) =>
	jsonString
		?.replace(/\$/g, '')
		?.replace(/<!--/g, '')
		?.replace(/-->/g, '')
		?.replace(/&quot;/g, '"');

export const parseBool = (value: any): boolean | null => {
	if (value === 'true') return true;
	if (value === 'false') return false;
	return null;
};
