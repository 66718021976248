import styled from '@emotion/styled';
import { TICKER_HEIGHTS, BACKGROUND_HEIGHTS, Z_INDICES, NOTCH_HEIGHT } from '../lib/utils/constants';

import { BreakPointToken } from '../hooks/useMediaQuery';

type SearchContainerProps = {
	expanded: boolean;
	initiallyOpen: boolean;
};

const {
	desktop: { opened: openedHeight, closed: closedHeight }
} = BACKGROUND_HEIGHTS;

const { default: minHeight } = TICKER_HEIGHTS;

export const SearchContainer = styled.section<SearchContainerProps>`
	// TODO: revisit once we've solidified stacking tokens from CL
	z-index: ${Z_INDICES.base};
	position: relative;
	transition: margin 0.4s linear;
	min-height: ${minHeight};
	margin: 0;

	@media (min-width: ${BreakPointToken.SmMin}px) {
		min-height: ${closedHeight};
	}

	@media (min-width: ${BreakPointToken.MdMin}px) {
		display: inline-block;
		width: 100%;
		margin-bottom: ${({ expanded, initiallyOpen }) => (expanded && !initiallyOpen ? `${NOTCH_HEIGHT}px` : '0')};
	}
`;
