import { BRANDS, CJS_BRAND } from '../lib/utils';
import { APP_CONSTANTS } from '../lib/utils/constants';
import { Config } from '../types/config';
import tokens from '@vaa-component-lib/shared.design-tokens/json/variables.json';
import { PRODUCT_TYPES } from '../types/criteria';

export const config: Config = {
	brand: BRANDS.VAA,
	key: CJS_BRAND.VAA,
	niceName: 'Virgin Atlantic',
	heading: 'So, where next?',
	summary: {
		title: 'Where next?',
		button: 'Start a search',
		editButton: 'Edit search',
		ticker: {
			title: 'Search for',
			hasImages: true,
			actions: ['flight & hotel in', 'flights to', 'holidays in'],
			locations: [
				{
					name: 'New York',
					image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/new-york-1.jpg`
				},
				{
					name: 'New York',
					image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/new-york-2.jpg`
				},
				{ name: 'Orlando', image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/orlando-1.jpg` },
				{ name: 'Orlando', image: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/ticker/orlando-2.jpg` }
			]
		}
	},
	theme: {
		palette: {
			background: tokens.color.light.background_red
		}
	},
	bookingTypes: [
		{
			id: '1',
			name: 'Flights',
			type: PRODUCT_TYPES.FLIGHT_ONLY,
			icon: 'icon-travel-plane'
		},
		{
			id: '2',
			name: 'Flight + Hotel',
			type: PRODUCT_TYPES.FLIGHT_HOTEL,
			icon: 'icon-travel-flight-plus-hotel',
			brand: CJS_BRAND.VAA
		},
		{
			id: '3',
			name: 'Flight + Car',
			type: PRODUCT_TYPES.FLIGHT_CAR,
			icon: 'icon-travel-car-hire',
			brand: CJS_BRAND.VAA
		},
		{
			id: '4',
			name: 'Holidays',
			type: PRODUCT_TYPES.HOLIDAY,
			icon: 'icon-travel-upgrade',
			brand: CJS_BRAND.VAH
		},
		{
			id: '5',
			name: 'Reward flights',
			type: PRODUCT_TYPES.REWARD_FLIGHT,
			icon: 'icon-travel-plane',
			meta: {
				logo: `${APP_CONSTANTS.BASE}${APP_CONSTANTS.ASSETS_PATH}/flying-club-logo.svg`,
				description:
					'Flying Club members can book flights using their Virgin Points. Not a member? <a href="https://travelplus.virginatlantic.com/flying-club/join" target="_blank">Join the family</a>'
			}
		}
	]
};
